import { Footer } from "../UTILITIES/Footer";
import '../STYLES/Login.css';
import { Spacer } from "../UTILITIES/Spacer";
import { useNavigate } from "react-router-dom";

export function Login() {
    const navigate = useNavigate();

    function onLogin() {
        const password = document.querySelector('#tbPassword').value;
        if (password === '12341234') {
            // LOGIN
            navigate("/CECfqP9mP9Lx8E6AlwsI");
        } else {
            alert('Incorrect Password. Please try again.');
            return;
        }
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            onLogin();
        }
    }

    return (
        <div>
            <div className="main p-md vertical-center">
                <div></div>
                <div className="login-form">
                    <p className="label">PASSWORD</p>
                    <Spacer height={10} />
                    <input
                        type="password"
                        className="textfield"
                        id="tbPassword"
                        placeholder="8 character password"
                        onKeyDown={handleKeyDown} // Add keydown listener here
                    />
                    <Spacer height={10} />
                </div>
                <div></div>
            </div>

            {/*  */}
            <Footer />
        </div>
    );
}