import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import '../STYLES/Info.css'
import { IoChatbox } from "react-icons/io5";
import { Clickable } from "../UTILITIES/Clickable";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { gemini_SendChat, gemini_StartChat } from "../gemini";
import { randomString } from "../functions";
import { Loading } from '../UTILITIES/Loading'
import { Accordion } from "../UTILITIES/Accordion";
import { TbChevronDown } from "react-icons/tb";
import { FaLink } from "react-icons/fa";

export function Info() {

    const [showChat, setShowChat] = useState(false);
    const [chat, setChat] = useState(null)
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false);
    const faqs = [
        { question: 'What is a Perm Loan?', answer: 'Permanent loans (perm loans) are long-term financing options used to fund stabilized properties, typically following construction or bridge loans. They offer lower interest rates and fixed terms, making them ideal for multifamily, retail, office, or industrial properties.', link: '' },
        { question: 'What is an Agency Loan?', answer: 'Agency loans are backed by government-sponsored enterprises (GSEs) like Fannie Mae, Freddie Mac, and HUD (FHA). They are commonly used for multifamily properties, offering competitive rates, flexible terms, and a streamlined process. Popular programs include affordable housing, workforce housing, and green financing initiatives.', link: 'https://trustsbaloans.com' },
    ]

    async function onSendMessage() {

        const text = document.querySelector("#tbText").value;
        document.querySelector('#tbText').value = "";

        setMessages((prev) => [...prev, {
            id: randomString(25),
            message: text,
            role: 'me',
            date: new Date().getTime()
        }])
        await gemini_SendChat(chat, text, (response) => {
            setMessages((prev) => [...prev, {
                id: randomString(25),
                message: response.response.text(),
                role: 'gemini',
                date: new Date().getTime()
            }])
        })
    }

    async function onStartNewChat() {
        // 

    }

    async function onStart() {
        setLoading(true);
        await gemini_StartChat(setChat);
        setLoading(false);
    }

    useEffect(() => {
        onStart()
    }, [])

    // 
    return <div>
        {loading && <Loading />}
        <Navigation />
        <div className="main relative">
            {/*  */}

            <div className="row">
                <div className="md2 lg2 p box">
                    <h1 className="text-xl">Everything you need to know about aquiring business loans.</h1>

                    <p className="orange">If you have any questions or need assistance quickly, feel free to use our virtual chat for immediate support.</p>
                </div>
            </div>
            <div className="row p box gap">
                {
                    faqs.map((faq, i) => {
                        return <div key={i} className="md2 lg3">
                            <Accordion
                                classes={'p box shadow-light radius-sm'}
                                top={
                                    <div className="separate-h vertical-center">
                                        <p className="text-md bold">{faq.question}</p>
                                        <TbChevronDown size={30} />
                                    </div>
                                } bottom={
                                    <div className="box p-v">
                                        <p className="space box">{faq.answer}</p>
                                        {
                                            faq.link !== undefined && faq.link !== "" &&
                                            <div>
                                                <br />
                                                <Clickable onPress={() => {
                                                    window.open(faq.link, '_blank')
                                                }}>
                                                    <div className="flex-row gap-sm vertical-center fit-width">
                                                        <p className="orange space">learn more</p>
                                                        <FaLink className="orange" size={13} />
                                                    </div>
                                                </Clickable>
                                            </div>
                                        }
                                    </div>
                                } />
                        </div>
                    })
                }


            </div>

            {/* CHAT BUTTOn */}
            <div className="fixed right bottom p">
                <Clickable onPress={() => {
                    setShowChat(true)
                }}>
                    <div className="chat-btn vertical-center">
                        <IoChatbox color="white" size={25} />
                    </div>
                </Clickable>
            </div>
        </div>


        {/* CHAT */}
        {
            showChat &&
            <div className="chat-box fixed bottom right separate-v box">
                <div className="bg-black white p separate-h vertical-center">
                    <p className="text-sm">Virtual Chat</p>
                    <Clickable onPress={() => { setShowChat(false) }}><IoMdClose color="white" size={25} /></Clickable>
                </div>
                <div className="chat-messages full-height scroll-y p-xs">
                    {
                        messages.map((mess, i) => {
                            return <div key={i} className={`${mess.role === "me" ? "bg-light-gray" : "bg-orange white   "} m-sm p-sm radius-sm`}>
                                <p className="text-xs">{mess.message}</p>
                            </div>
                        })
                    }
                </div>
                {/* TEXTFIELD */}
                <div className="box flex-row vertical-center">
                    <input id="tbText" type="text" placeholder="Type message here..." className="full-width p box no-border" />
                    <div className="p-h">
                        <Clickable onPress={() => { onSendMessage() }}>
                            <IoSend size={22} />
                        </Clickable>
                    </div>
                </div>
            </div>
        }
        <Footer />
    </div>
}