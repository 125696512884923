import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import img1 from '../IMAGES/buildings1.png'
import '../STYLES/Home.css'

export function Home() {
    return <div>
        <Navigation />
        <div className="main">
            {/*  */}
            <div className="grid-2">
                <div className="separate-v p-md">
                    <h1 className="page-main-title">Empowering Your Business Dreams: Find the Perfect Loans and Real Estate Solutions Today!</h1>
                    <p className="page-text">At Quik Biz Lending, we specialize in connecting businesses with tailored loan options and prime real estate opportunities. Whether you’re looking to expand, relocate, or start fresh, our streamlined process and expert support make finding the perfect solution quick and hassle-free. Your growth is our priority!</p>
                </div>
                <img src={img1} className="full-width" />
            </div>
            <div className="home-banner separate-v">
                <div></div>
                <div className="grid-3">
                    <div className="p-lg vertical gap-sm bg-light">
                        <h1 className="page-topic">Small Business Lending</h1>
                        <p className="page-text">Quik Biz Lending helps businesses with $600,000 in annual revenue and $150,000 in profit secure tailored financing solutions. We serve industries like restaurants, medical practices, tire shops, motels, auto repair, and contractors. Let us help you grow with confidence.</p>
                    </div>
                    <div className="p-lg vertical gap-sm bg-light">
                        <h1 className="page-topic">Unlock Business Growth</h1>
                        <p className="page-text">Quik Biz Lending offers SBA and commercial loans of up to $5 million to help businesses expand, invest in equipment, or acquire property. With tailored financing options, we provide the resources you need to take your business to the next level.</p>
                    </div>
                    <div className="p-lg vertical gap-sm bg-light">
                        <h1 className="page-topic">Invest In Your Business' Future</h1>
                        <p className="page-text">We understand that every business has unique financial needs. That’s why we offer a range of flexible loan options designed to support your growth. Whether you’re expanding operations, managing cash flow, or investing in new equipment, our loan products cater to businesses of all sizes.</p>
                    </div>
                </div>
                <div></div>
            </div>
        </div>
        <Footer />
    </div>
}