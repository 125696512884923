import { useEffect, useState } from "react";
import { Footer } from "../UTILITIES/Footer";
import { Spacer } from "../UTILITIES/Spacer";
import { firebase_GetAllDocumentsQueriedPaginated } from "../firebase";
import { formatDateTime } from "../functions";
import '../STYLES/Entries.css'

export function Entries() {
    var lastDoc = null;
    const [entries, setEntries] = useState([])

    async function getAllEntries() {
        await firebase_GetAllDocumentsQueriedPaginated('Inquiries', 100, lastDoc, (docs, lastDoc) => {
            setEntries(docs)
        })
    }

    useEffect(() => {
        getAllEntries()
    }, [])

    return <div>

        <div className="main p">
            <p className="page-main-title">Inquiries</p>
            <Spacer height={15} />
            <div className="table">
                <div className="tr">
                    <p className="td bold">Date</p>
                    <p className="td bold">Contact Name</p>
                    <p className="td bold">Business Type</p>
                    <p className="td bold">Phone</p>
                    <p className="td bold">Email</p>
                    <p className="td bold">Address</p>
                    <p className="td bold">Length of Operation</p>
                    <p className="td bold">Loan Amount</p>
                    <p className="td bold">Owner Occupied Plan</p>
                </div>
                {
                    entries.map((entry, i) => {
                        return <div className="tr" key={i}>
                            <p className="td">{formatDateTime(new Date(entry.date))}</p>
                            <p className="td">{entry.contactName}</p>
                            <p className="td">{entry.businessType}</p>
                            <p className="td">{entry.phone}</p>
                            <p className="td">{entry.email}</p>
                            <p className="td">{entry.address}</p>
                            <p className="td">{entry.length} years</p>
                            <p className="td">${entry.loanAmount}</p>
                            <p className="td">{entry.owner}</p>
                        </div>
                    })
                }
            </div>
        </div>

        {/*  */}
        <Footer />
    </div>
}