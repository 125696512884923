import { Navigation } from "../UTILITIES/Navigation";
import { Footer } from '../UTILITIES/Footer';
import { Spacer } from "../UTILITIES/Spacer";
import img1 from '../IMAGES/form1.jpg';
import { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Loading } from '../UTILITIES/Loading'
import { firebase_CreateDocument } from '../firebase'

export function Quote() {
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const autocompleteRef = useRef(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyA1ebAwpD6h_j9PPpXD8GYJczVMpjH-7A4", // replace with your API key
        libraries: ['places'], // load places library for autocomplete
    });

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
        borderRadius: '10px',
        marginTop: '10px'
    };

    const center = {
        lat: lat || 37.7749, // default latitude (San Francisco)
        lng: lng || -122.4194, // default longitude (San Francisco)
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (isLoaded && autocompleteRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current);
            autocomplete.setFields(['geometry', 'formatted_address']); // Specify the required data

            // Listener for when a place is selected
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const location = place.geometry.location;
                    setLat(location.lat());
                    setLng(location.lng());
                    setAddress(place.formatted_address);
                }
            });
        }
    }, [isLoaded]);

    // Handle form submission
    async function onSubmitForm() {
        const contactName = document.querySelector('#tbContactName').value;
        if (contactName === "") {
            alert("Please provide a valid Contact Name.");
            return;
        }

        const phone = document.querySelector('#tbPhone').value;
        if (phone === "") {
            alert("Please provide a valid Phone number.");
            return;
        }

        const email = document.querySelector('#tbEmail').value;
        if (email === "") {
            alert("Please provide a valid Email.");
            return;
        }

        const businessType = document.querySelector('#tbBusinessType').value;
        if (businessType === "") {
            alert("Please provide a valid Business Type.");
            return;
        }

        const loanAmount = document.querySelector('#ddLoanAmount').value;
        if (loanAmount === "") {
            alert("Please provide a valid Loan Amount.");
            return;
        }
        if (address === "") {
            alert("Please provide a valid Address.");
            return;
        }
        const length = document.querySelector('#ddLength').value;
        if (length === "") {
            alert("Please provide a valid Length Of Operation.");
            return;
        }
        const owner = document.querySelector('#ddOwner').value;
        if (owner === "") {
            alert("Please provide a valid Owner Occupied option.");
            return;
        }

        setLoading(true)
        firebase_CreateDocument('Inquiries', {
            contactName: contactName,
            phone: phone,
            email: email,
            businessType: businessType,
            loanAmount: loanAmount,
            address: address,
            length: length,
            owner: owner,
            date: new Date().getTime()
        }, (success) => {
            if (success) {
                document.querySelector("#tbContactName").value = "";
                document.querySelector("#tbPhone").value = "";
                document.querySelector("#tbEmail").value = "";
                document.querySelector("#tbBusinessType").value = "";
                document.querySelector("#ddLoanAmount").value = "";
                document.querySelector("#tbAddress").value = "";
                setAddress("")
                document.querySelector("#ddLength").value = "";
                document.querySelector("#ddOwner").value = "";
                // 
                setLoading(false)
                alert("Success! We will reach out to you soon.")
            } else {
                alert("There was a problem with your request. Please try again later or check your internet connection.");
                setLoading(false)
                return;
            }
        })

    }

    return (
        <div>
            {loading && <Loading />}
            <Navigation />
            <div className="main">
                <div className="p">
                    <h1 className="page-main-title">Submit an inquiry today!</h1>
                    <Spacer height={20} />
                    <p className="page-caption">Every small business deserves the opportunity to thrive in an ideal location, with the right equipment and the potential for continued growth. If your business generates approximately $600,000 in annual revenue or $150,000 in profits, we invite you to complete this form. Let us help you achieve your goals and unlock your business’s full potential.</p>
                </div>
                <Spacer height={30} />
                <div className="grid-2">
                    <div className="form p">
                        {/* FORM CONTENT */}
                        <div className="split">
                            <div className="form-pair">
                                <p className="label">CONTACT NAME</p>
                                <input type="text" className="textfield radius" id="tbContactName" />
                            </div>
                            <div className="form-pair">
                                <p className="label">PHONE</p>
                                <input type="text" className="textfield radius" id="tbPhone" />
                            </div>
                        </div>
                        {/* EMAIL */}
                        <div className="form-pair">
                            <p className="label">EMAIL</p>
                            <input type="text" className="textfield radius" id="tbEmail" />
                        </div>
                        {/* BUSINESS TYPE */}
                        <div className="form-pair">
                            <p className="label">BUSINESS TYPE (Specify your category)</p>
                            <input type="text" className="textfield radius" id="tbBusinessType" />
                        </div>
                        {/* LOAN AMOUNT */}
                        <div className="form-pair">
                            <p className="label">LOAN AMOUNT REQUESTED</p>
                            <select className="dropdown" id="ddLoanAmount">
                                <option value={""}>-- select one --</option>
                                <option value="5000000">up to $5,000,000</option>
                            </select>
                        </div>
                        {/* ADDRESS MAP */}
                        <div>
                            <div>
                                <p className="label">ADDRESS</p>
                                <input
                                    ref={autocompleteRef}  // Reference to the autocomplete input field
                                    id="tbAddress"
                                    type="text"
                                    className="textfield"
                                    value={address}
                                    placeholder="Search for your address"
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>

                            {/* Map Section */}
                            <div className='create-map'>
                                {isLoaded && (
                                    <GoogleMap
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={17}
                                        center={center}
                                    >
                                        {lat && lng && <Marker position={{ lat, lng }} />}
                                    </GoogleMap>
                                )}
                            </div>
                        </div>
                        {/* TIME OPEN */}
                        <div className="form-pair">
                            <p className="label">LENGTH OF OPERATION (in years)</p>
                            <select className="dropdown" id="ddLength">
                                <option value={""}>-- select one --</option>
                                <option value="1">1 year</option>
                                <option value="2">2 years</option>
                                <option value="3">3 years</option>
                                <option value="4">4 years</option>
                                <option value="5">5 years</option>
                                <option value="6">6 years</option>
                                <option value="7">7 years</option>
                                <option value="8">8 years</option>
                                <option value="9">9 years</option>
                                <option value="10">10 years</option>
                                <option value="11">11 years</option>
                                <option value="12">12 years</option>
                                <option value="13">13 years</option>
                                <option value="14">14 years</option>
                                <option value="15">15 years</option>
                                <option value="16">16 years</option>
                                <option value="17">17 years</option>
                                <option value="18">18 years</option>
                                <option value="19">19 years</option>
                                <option value="20">20 years</option>
                                <option value="21">21 years</option>
                                <option value="22">22 years</option>
                                <option value="23">23 years</option>
                                <option value="24">24 years</option>
                                <option value="25">25 years</option>
                                <option value="25+">25+ years</option>
                            </select>
                        </div>
                        {/* BUYING OR REFINANCING */}
                        <div className="form-pair">
                            <p className="label">OWNER OCCUPIED COMMERCIAL PROPERTY</p>
                            <select className="dropdown" id="ddOwner">
                                <option value={""}>-- select one --</option>
                                <option value="buying">Buying</option>
                                <option value="refinancing">Refinancing</option>
                            </select>
                        </div>
                        {/* FINISH */}
                        <Spacer height={20} />
                        <p className="alert text-center">Please review the information above for accuracy to ensure we can better assist you with the next steps.</p>
                        <Spacer height={20} />
                        <div onClick={() => { onSubmitForm() }} className="main-button pointer"><p>Submit</p></div>
                        <Spacer height={40} />
                    </div>

                    <div className="p">
                        <img src={img1} className="full-width radius" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}