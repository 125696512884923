// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { collection, doc, getDocs, getFirestore, limit, orderBy, query, setDoc, startAfter } from "firebase/firestore";
import { randomString } from "./functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCHrg5ZLD3ecW201dsLx0eeRhu40rC_eF8",
    authDomain: "quik-biz-lending.firebaseapp.com",
    projectId: "quik-biz-lending",
    storageBucket: "quik-biz-lending.firebasestorage.app",
    messagingSenderId: "1085452150393",
    appId: "1:1085452150393:web:f92ef31181d336813216a8",
    measurementId: "G-7JVY1SPH2K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// 
const db = getFirestore(app);

export async function firebase_CreateDocument(coll, args, success) {
    await setDoc(doc(db, coll, randomString(25)), args).then(() => {
        success(true)
    }).catch((error) => {
        success(false)
    })
}

export async function firebase_GetAllDocumentsQueriedPaginated(coll, pageLimit, lastVisibleDoc = null, success) {
    try {
        // Construct query with optional pagination
        let q = query(
            collection(db, coll),
            orderBy('date', "desc"), // Replace 'yourField' with the field you want to order by
            limit(pageLimit)
        );

        if (lastVisibleDoc) {
            q = query(
                collection(db, coll),
                orderBy("date", "desc"),
                startAfter(lastVisibleDoc),
                limit(pageLimit)
            );
        }

        // Fetch documents
        const querySnapshot = await getDocs(q);

        // Get the last visible document for next page
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Process the fetched documents
        const documents = [];
        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, ...doc.data() });
        });

        // Call success callback with documents and the last visible document
        if (success) {
            success(documents, lastVisible);
        }
    } catch (error) {
        console.error("Error fetching documents:", error);
    }
}

