import { useNavigate } from 'react-router-dom'
import '../STYLES/Footer.css'

export function Footer() {
    const navigate = useNavigate();

    return <div className="footer">
        <div onClick={() => { navigate('/') }}>
            <h1 className='footer-logo pointer'>Quik Biz Lending</h1>
        </div>
        <div className='footer-links'>
            <div className='pointer' onClick={() => {
                navigate('/info')
            }}><p className='footer-link'>Info</p></div>
            <div className='pointer' onClick={() => {
                navigate('/inquiry')
            }}><p className='footer-link'>Submit Inquiry</p></div>
        </div>
        <div>
            <p className='copyright'>&copy; Copyright 2024. All Rights Reserved.</p>
        </div>
    </div>
}