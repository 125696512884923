import { useState } from "react";
import { Clickable } from "./Clickable";

export function Accordion({ top, bottom, classes }) {
    const [showBottom, setShowBottom] = useState(false);
    return <div className={classes}>
        <Clickable onPress={() => { setShowBottom((prev) => !prev) }}>{top}</Clickable>
        {
            showBottom &&
            <div>
                {bottom}
            </div>
        }
    </div>
}