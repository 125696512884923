import { useState } from "react"
import { RiMenuFill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";

import '../STYLES/Navigation.css'
import { useNavigate } from "react-router-dom";
import { MdArrowOutward } from "react-icons/md";
import { Spacer } from "./Spacer";

export function Navigation() {
    const [showNav, setShowNav] = useState(false);
    const navigate = useNavigate();

    // 
    return <div className="nav-top">
        <div className="pointer" onClick={() => { navigate('/') }}>
            <p className="nav-logo">Quik<br />Biz<br />Lending</p>
        </div>
        <div className="nav-right">
            <div onClick={() => { setShowNav(true) }} className="burger pointer">
                <RiMenuFill size={32} />
            </div>
            <div className="nav-links">
                <div onClick={() => { navigate('/info') }} className="nav-link pointer">
                    <p>Info</p>
                </div>
                <div onClick={() => {
                    navigate('/inquiry')
                }} className="nav-link pointer quote">
                    <p>Submit Inquiry</p>
                </div>
            </div>
        </div>

        {showNav && <div className="nav-body">
            <div>
                <div className="nav-body-top">
                    <div>
                        <p className="nav-logo">Quik<br />Biz<br />Lending</p>
                    </div>
                    <div onClick={() => { setShowNav(false) }}>
                        <IoMdClose size={28} />
                    </div>
                </div>
                <div className="nav-body-links">
                    <div className="nav-body-link pointer" onClick={() => {
                        setShowNav(false);
                        navigate('/');
                    }}>
                        <p>Home</p>
                        <MdArrowOutward className="nav-body-icon" />
                    </div>
                    <div className="nav-body-link pointer" onClick={() => {
                        setShowNav(false);
                        navigate('/info');
                    }}>
                        <p>Info</p>
                        <MdArrowOutward className="nav-body-icon" />
                    </div>
                    <div className="nav-body-link pointer" onClick={() => {
                        setShowNav(false);
                        navigate('/inquiry');
                    }}>
                        <p>Submit Inquiry</p>
                        <MdArrowOutward className="nav-body-icon" />
                    </div>
                </div>
            </div>
            <div>
                <p className="copyright">Copyright 2024. All Rights Reserved.</p>
            </div>
        </div>}
    </div>
}

