import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Home } from './PAGES/Home';
import './App.css'
import { Quote } from './PAGES/Quote';
import { Info } from './PAGES/Info';
import { Login } from './PAGES/Login';
import { Entries } from './PAGES/Entries';
import 'react-library/src/App.css'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/inquiry",
    element: <Quote />,
  },
  {
    path: "/info",
    element: <Info />,
  },
  {
    path: "/admin",
    element: <Login />,
  },
  {
    path: "/CECfqP9mP9Lx8E6AlwsI",
    element: <Entries />
  }
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

